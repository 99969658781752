<template>
  <v-container fluid>
    <v-row class="mb-n12">
      <v-col cols="3" class="d-flex flex-column">
        <span class="text-caption">
          Fatura
        </span>

        <span class="text-subtitle">
          {{ model.invoice || "Unificada" }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Data de Vencimento
        </span>

        <span class="text-subtitle">
          {{ (model.due_date || "-") | formatDate }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column" v-if="model.currency != 'BRL'">
        <span class="text-caption">
          Taxa de Conversão
        </span>

        <span class="text-subtitle">
          {{ getCurrencyValue }}
        </span>
      </v-col>

      <v-col v-if="model.currency != 'BRL'"> </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor Original
        </span>

        <span class="text-subtitle">
          {{ model.currency }} {{ model.total | withoutSymbolCurrency }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor dos Descontos
        </span>

        <span class="text-subtitle">
          BRL {{ discounts | withoutSymbolCurrency }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor dos Juros
        </span>

        <span class="text-subtitle">
          {{ nonNegativeCurrency(taxValue) }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor Final
        </span>

        <span class="text-subtitle">
          {{ model.result_total | toCurrency }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";

export default {
  components: {},
  name: "PreviewBilletDialogForm",
  mixins: [ModelManagementMixin],
  computed: {
    taxValue() {
      const chargesTotals = this.model.result_charges || 0;

      return chargesTotals || 0;
    },
    discounts() {
      const discount = this.model.result_entries || 0;

      if (discount < 0) {
        return -discount;
      }

      return discount || 0;
    },
    getCurrencyValue() {
      return this.formatExchangeCurrency(
        this.model.actual_currency_value || this.model.currency_value
      );
    }
  },
  created() {
    this.invoiceVerify = new InvoiceVerify();
  },
  methods: {
    nonNegativeCurrency(item) {
      const value = this.toCurrency(item);

      const withoutSymbol = `${value}`.replace("-", "");

      return withoutSymbol;
    },
    formatExchangeCurrency(value) {
      return this.toCurrency(value, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
      });
    }
  }
};
</script>

<style></style>
