import DiscountReason from "@/pages/DiscountReason/DiscountReason.vue";
import DiscountReasonSave from "@/pages/DiscountReasonSave/DiscountReasonSave.vue";

import RefundReason from "@/pages/RefundReason/RefundReason.vue";
import RefundReasonSave from "@/pages/RefundReasonSave/RefundReasonSave.vue";

import Refund from "@/pages/Refund/Refund.vue";
import RefundSave from "@/pages/RefundSave/RefundSave.vue";

import Invoice from "@/pages/Invoice/Invoice.vue";
import WriteOffPayment from "@/pages/WriteOffPayment/WriteOffPayment.vue";

import DiscountApprovalGroups from "@/pages/DiscountApprovalGroups/DiscountApprovalGroups.vue";
import DiscountApprovalGroupsSave from "@/pages/DiscountApprovalGroups/DiscountApprovalGroupsSave.vue";

import DiscountTypes from "@/pages/DiscountTypes/DiscountTypes.vue";
import DiscountTypesSave from "@/pages/DiscountTypesSave/DiscountTypesSave.vue";

const routes = [
  {
    description: "Home",
    path: "/home",
    name: "Home",
    group_type: "",
    icon: "mdi-home",
    component: Invoice
  },
  {
    description: "Reembolsos",
    group_type: "",
    icon: "mdi-currency-usd",
    path: "/invoice/refund",
    name: "Refund",
    component: Refund
  },
  {
    path: "/invoice/refund/:id",
    name: "RefundSave",
    component: RefundSave
  },
  {
    path: "/refund-reason/:id",
    name: "RefundReasonSave",
    component: RefundReasonSave
  },
  {
    description: "Justificativas de Reembolso",
    permission: "/invoice/refund/handle",
    path: "/refund-reason/",
    name: "RefundReason",
    group_type: "registers",
    component: RefundReason
  },
  {
    path: "/discount-reason/:id",
    name: "DiscountReasonSave",
    component: DiscountReasonSave
  },
  {
    permission: "/invoice/discount",
    description: "Motivos de Desconto",
    path: "/discount-reason/",
    name: "DiscountReason",
    group_type: "registers",
    component: DiscountReason
  },
  {
    permission: "/invoice/write-off-payment",
    description: "Ajuste de Faturas",
    path: "/invoice/write-off-payment",
    group_type: "",
    icon: "mdi-history",
    component: WriteOffPayment
  },
  {
    description: "Grupos Aprovação de Desconto",
    permission: "/discount-approval-groups",
    path: "/discount-approval-groups/",
    name: "DiscountApprovalGroups",
    group_type: "registers",
    component: DiscountApprovalGroups
  },
  {
    path: "/discount-approval-groups/:id",
    name: "DiscountApprovalGroupsSave",
    component: DiscountApprovalGroupsSave
  },
  {
    description: "Tipos de Desconto",
    permission: "/discount-types",
    path: "/discount-types/",
    name: "DiscountTypes",
    group_type: "registers",
    component: DiscountTypes
  },
  {
    path: "/discount-types/:id",
    name: "DiscountTypesSave",
    component: DiscountTypesSave
  }
];

export default routes;
