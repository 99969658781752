<template>
  <v-container class="pa-0 mb-4">
    <v-row no-gutters>
      <v-col sm="12" class="text-caption font-weight-bold">
        Informações da Fatura
      </v-col>

      <v-col sm="12" class="flex-row d-flex flex-wrap pa-2">
        <extra-info-tile>
          <template #title>
            Nº Fatura
          </template>

          <template #message>
            {{ invoice.invoice }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            BL
          </template>

          <template #message>
            {{ invoice.bl }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Booking
          </template>

          <template #message>
            {{ invoice.booking }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Data de Saída
          </template>

          <template #message>
            {{ invoice.departure_date | formatDate }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Porto de Embarque
          </template>

          <template #message>
            {{ invoice.boarding_point_name }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Navio
          </template>

          <template #message>
            {{ invoice.ship }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Viagem
          </template>

          <template #message>
            {{ invoice.travel }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Data de Chegada
          </template>

          <template #message>
            {{ invoice.arrival_date | formatDate }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Porto de Desembarque
          </template>

          <template #message>
            {{ invoice.landing_point_name }}
          </template>
        </extra-info-tile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExtraInfoTile from "./ExtraInfoTile.vue";

export default {
  components: {
    ExtraInfoTile
  },
  name: "InvoiceInfo",
  props: {
    invoice: Object
  },
  methods: {}
};
</script>

<style></style>
