<template>
  <v-form v-model="valid" ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" v-show="loading">
          <v-progress-linear
            indeterminate
            color="primary"
            class="d-flex flex-item mx-auto"
          />
        </v-col>

        <v-col xl="6" lg="6" md="6" sm="6" xs="12">
          <v-text-field
            label="Motivo"
            v-model="discountReason.name"
            placeholder="Digite..."
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "DiscountReasonSaveForm",
  props: {
    value: Object,
    loading: Boolean
  },
  data() {
    return {
      rules: {
        required: v =>
          (v !== "undefined" && v !== null && v !== "") || "Campo Necessário"
      },
      valid: {},
      discountReason: this.value,
      status: [
        {
          text: "Ativo",
          value: 1
        },
        {
          text: "Inativo",
          value: 0
        }
      ]
    };
  },
  watch: {
    value(updatedDiscountReason) {
      this.discountReason = updatedDiscountReason;
    },
    discountReason() {
      this.$emit("input", this.discountReason);
    }
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate();

      return valid;
    }
  }
};
</script>
