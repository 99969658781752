import { appRoutes } from "@/router/routes";
export default class Accesses {
  routes = [];
  roles = [];

  nonGroupedAccesses = [];
  groupedAccesses = {
    users: {
      accesses: [],
      name: "Usuários",
      icon: "mdi-account-multiple"
    },
    registers: {
      accesses: [],
      name: "Cadastros",
      icon: "mdi-contacts"
    },
    reports: {
      accesses: [],
      name: "Relatórios",
      icon: "mdi-file-multiple"
    }
  };

  constructor() {
    this.routes = appRoutes;
  }

  loadAccesses(store) {
    this.roles = store.state.roles;

    this.serializeAccesses();

    return this;
  }

  serializeAccesses() {
    if (!this.roles) {
      throw new Error("Permissões não definidas");
    }

    this.serializeNonGroupedAccesses();

    this.serializeGroupedAccesses();

    const result = {
      nonGroupedAccesses: this.nonGroupedAccesses,
      groupedAccesses: this.groupedAccesses
    };

    return result;
  }

  verifyAccess(route) {
    const hasGrouper = route.group_type;

    if (hasGrouper) {
      return false;
    }

    const isAccessible = this.rolesIsAccessible(route.permission || route.path);

    return isAccessible;
  }

  serializeNonGroupedAccesses() {
    this.nonGroupedAccesses = this.routes.filter(route =>
      this.verifyAccess(route)
    );

    return this.nonGroupedAccesses;
  }

  serializeGroupedAccesses() {
    const groupedRoutes = this.routes.filter(route => {
      const hasGrouper = route.group_type;

      if (!hasGrouper) {
        return false;
      }

      const isAccessible = this.rolesIsAccessible(
        route.permission || route.path
      );

      return isAccessible;
    });

    groupedRoutes.forEach(route => this.addRouteToGroup(route));

    return this.groupedAccesses;
  }

  addRouteToGroup(route) {
    try {
      const group = this.groupedAccesses[route.group_type].accesses || [];

      group.push(route);

      this.groupedAccesses[route.group_type].accesses = group;
    } catch (error) {
      console.error("accesses::addRouteToGroup - ", error);
    }
  }

  rolesIsAccessible(route) {
    return this.roles.some(role => {
      const isTheSameRole = this.routeVerify(route, role.route);

      const canView = role.operation == "read";
      const canEdit = role.operation == "write";
      const canExecute = role.operation == "exec";

      return isTheSameRole && (canExecute || canView || canEdit);
    });
  }

  routeVerify(route, role) {
    if (typeof route == "string") {
      return route == role;
    } else if (typeof route == "object") {
      return route.includes(role);
    }
  }
}
