<template>
  <v-container fluid>
    <v-row align-content="stretch">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        class="text-center backgroundImageMobile"
        style="background: white"
      >
        <v-form ref="form">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-text-field
                  ref="login"
                  label="Limite de Dias de Multa e Juros"
                  v-model="payer.penalty_days_limit"
                  required
                  color="text"
                  @keydown.enter="submit"
                />
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-text-field
                  label="Taxa Condição Especial (Câmbio)"
                  v-model="payer.exchange_rate"
                  color="text"
                  :disabled="roe.enable"
                  required
                />
              </v-col>

              <v-col cols="12" x="12" lg="4" md="6">
                <v-text-field
                  label="Câmbio Dia"
                  v-model="payer.exchange_day"
                  type="number"
                  @input="validateMinimumValue"
                  :rules="[rules.greaterThanZero]"
                  v-mask="'####'"
                />
              </v-col>

              <v-col cols="12" xs="12" lg="4" md="6">
                <v-select
                  label="Modalidade da PTAX"
                  v-model="payer.ptax"
                  :items="ptaxTypeItem"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <v-switch
                  v-model="roe.enable"
                  color="success"
                  hide-details
                  inset
                >
                  <template v-slot:label>
                    <h3>ROE Programada</h3>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="12" v-show="roe.enable">
                <hr />
              </v-col>
              <v-col cols="6" v-show="roe.enable">
                <v-text-field
                  label="Taxa Condição Especial (Câmbio)"
                  v-model="roe.exchange_rate_roe"
                  color="text"
                />
              </v-col>
              <v-col cols="6" v-show="roe.enable">
                <v-text-field
                  label="Quantidade de Dia(s)"
                  v-model="roe.exchange_day_roe"
                  color="text"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";
import Vue from "vue";

export default Vue.extend({
  name: "Levy",
  props: {
    value: Object,
    roe: Object
  },
  mixins: [FormMixin],
  data() {
    return {
      loading: false,
      payer: this.value,
      ptaxTypeItem: [
        { text: "Compra", value: "buy" },
        { text: "Venda", value: "sale" }
      ],
      menu: false
    };
  },
  watch: {
    payer() {
      this.$emit("input", this.payer);
    },
    value() {
      this.payer = this.value;
    }
  },
  mounted() {},
  methods: {
    validateMinimumValue() {
      if (Number(this.payer.exchange_day) < 0) {
        this.payer.exchange_day = 0;

        this.$forceUpdate();
      }

      if (Number.isNaN(Number(this.payer.exchange_day))) {
        this.payer.exchange_day = 0;

        this.$forceUpdate();
      }
    }
  }
});
</script>
